<template>
  <div class="w-full grid ">
     <div class="text-center grid grid-cols-8"><button @click="addOperator(op)" class="bg-yellow-300 px-2 m-1" v-for="op in validOperators" :key="op">{{op}}</button>
     <button @click="del" class="bg-yellow-300 px-2 m-1">&#60;&#60;</button></div>
    <div class="text-center">{{ moment(today).format('D/M/YYYY') }}</div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-2/5 px-3 mb-6 md:mb-0">
        <div class="text-center px-5 grid grid-cols-2"><button @click="addToLeft(c.num)" class="bg-yellow-300 px-2 m-1 text-center" :class="{ 'bg-green-600':c.used }"
            v-for="c in canUseLeft" :key="c.num" :value="c.num" type="text" >{{c.num}}</button></div>
        <div @click="setFocus('left')" 
          class="bg-yellow-100 shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
          >{{left}}&nbsp;</div>
        <div  :class="{'bg-green-600': win}" class="text-center px-5">{{ resultLeft }}</div>
      </div>
      <div class="w-1/5 px-3 mb-1 md:mb-1 text-center">
        <div class="text-center px-5"> &nbsp;</div>
         <div class="text-center px-5"> &nbsp;</div>
          <div class="text-center px-5"> &nbsp;</div>
        =
      </div>
      <div class="w-2/5 px-3">
        <div class="text-center px-5 grid grid-cols-2"><button  @click="addToRight(c.num)" class="bg-yellow-300 px-2 m-1 text-center" :class="{ 'bg-green-600': c.used }"
            v-for="c in canUseRight" :key="c.num" type="text">{{c.num}}</button></div>
        <div class="bg-yellow-100 shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" @click="setFocus('right')"
          >{{right}}&nbsp;</div>
        <div :class="{'bg-green-600': win}" class="text-center px-5">{{ resultRight }}</div>
        
      </div>
       <div class="text-center px-5"> &nbsp;</div>
       <div v-if="win"  class="text-center w-full px-10">Yey!! 🥳🥳🎉🎉</div>
    </div>


  </div>
</template>
<script>

const math = require('mathjs');
import moment from 'moment';
export default {
  data() {
    return {
      today: new Date(),
      left: '',
      right: '',
      focus: 'left',
      canUseRight: [],
      countRight: {},
      usingRight:{},
      canUseLeft: [],
      countLeft: {},
      usingLeft:{},
      validOperators:['/','+','-','*','(',')','^']
    }
  },
  methods: {
    del(){
      if (this.focus === 'right')
      {
        this.right= this.right.slice(0,this.right.length-1)
        this.validateRight()
      }
      else
      {
         this.left= this.left.slice(0,this.left.length-1)
         this.validateLeft()
      }
       
        
    },
    setFocus(f){
     
      this.focus = f
    },
    addOperator(op){
      if (this.focus === 'right')
      {
        this.right=this.right+op
        this.validateRight()
      }
      else
      {
         this.left= this.left+op
         this.validateLeft()
      }
       
        
    },
    addToLeft(num){
      this.focus = 'left'
      this.left= this.left+num
      this.validateLeft()
    },
    addToRight(num){
      this.focus='right'
      this.right= this.right+num
      this.validateRight()
    },
    validateRight() {
     
      for (var i = 0; i < this.canUseRight.length; i++) {
        this.canUseRight[i].used = false
      }     
        var all = this.right.split("")
        for (var x = 0; x < all.length; x++){
         
          var found = this.canUseRight.find(({num, used})=> num == all[x] && used === false)
         
          if (found){
            found.used = true
          }
          else if (parseInt(all[x]) || this.validOperators.indexOf(all[x]) == -1)
          {
            this.right = this.right.slice(0 , this.right.length - 1)
                   
        }
        }
        
    },
     validateLeft() {
     
      for (var i = 0; i < this.canUseLeft.length; i++) {
        this.canUseLeft[i].used = false
      }     
        var all = this.left.split("")
        for (var x = 0; x < all.length; x++){
         
          var found = this.canUseLeft.find(({num, used})=> num == all[x] && used === false)
         
          if (found){
            found.used = true
          }
          else if (parseInt(all[x]) || this.validOperators.indexOf(all[x]) == -1)
          {
            this.left = this.left.slice(0 , this.left.length - 1)
                   
        }
        }
        
    },

  },
  computed: {
    win(){      

      return this.resultLeft === this.resultRight && parseInt(this.resultRight) && !this.canUseRight.find(({used})=> used === false) && !this.canUseLeft.find(({used})=> used === false)
    },
    day() {
      return new Date().getDate();
    },
    month() {
      return new Date().getMonth() + 1;
    },
    year() {
      return new Date().getFullYear();
    },
    resultLeft() {
      try {
        var res = math.evaluate(this.left)
        if (Number.isInteger(res)) {
          return res
        }
        else if (typeof res !== 'undefined') {
          return 'No Integer'
        }
        else {
          return ''
        }
      } catch (error) {
        return ''
      }
    },
    resultRight() {
       try {
        var res = math.evaluate(this.right)
        if (Number.isInteger(res)) {
          return res
        }
        else if (typeof res !== 'undefined') {
          return 'No Integer'
        }
        else {
          return ''
        }
      } catch (error) {
        return ''
      }
    }
  },
  created() {
    this.moment = moment

  },
  mounted() {
    var canUseRight =  new Date().getFullYear().toString().split("")
    for (var i = 0; i < canUseRight.length; i++) {
      this.canUseRight.push({
        num: canUseRight[i],
        used: false
      });
    }

    var canUseLeft = this.day.toString().split("");
      canUseLeft = canUseLeft.concat(this.month.toString().split(""))
    for (var x = 0; x < canUseLeft.length; x++) {
      this.canUseLeft.push({
        num: canUseLeft[x],
        used: false
      });
    }
   
    this.canUseRight.forEach(el=> {
      this.countRight[el] = (this.countRight[el] || 0) + 1;
      this.usingRight[el] = 0;
    });
 this.canUseLeft.forEach(el=> {
      this.countLeft[el] = (this.countLeft[el] || 0) + 1;
      this.usingLeft[el] = 0;
    });
  }
}
</script>
