<template>

 <header class="header">     
      <Nav></Nav>    
  </header>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->  
    <Game></Game>
</template>

<script>
import Game from './components/Game.vue'

export default {
  name: 'App',
  components: {
    Game
  }
}
</script>

<style>
</style>
