import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import Nav from './components/Nav'
import Icon from './components/Icon'
import FAQ from './components/FAQ'
import CanUse from './components/CanUse'
const app = createApp(App)

app.component('Icon',Icon)
app.component('Nav',Nav)
app.component('FAQ',FAQ)
app.component('CanUse',CanUse)
app.mount('#app')

