<template>
  <div>
    <a href="#" v-on:click="toggleModal()">
      <Icon icon="question-circle"></Icon>
    </a>
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 class="text-md font-semibold">
              Game Rules
            </h3>
            <button v-on:click="toggleModal()">
               <Icon icon="x"></Icon>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="my-4 text-slate-500 text-lg leading-relaxed">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed purus sollicitudin, convallis sem id, mollis dui. Donec eget faucibus nunc. Ut eu dolor luctus, pellentesque mi sit amet, pretium turpis. Pellentesque a felis quam. Mauris rhoncus consequat nisl. Duis vehicula enim mauris. Nunc aliquet, risus eget lacinia hendrerit, urna turpis efficitur magna, nec consectetur erat justo vitae nunc. Suspendisse potenti. Etiam consectetur leo vitae aliquam auctor. Pellentesque congue consequat dolor, sit amet fringilla tortor rhoncus et. Praesent vestibulum, magna eget lobortis vulputate, odio orci suscipit arcu, porta venenatis odio nulla a augue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
              Got it
            </button>
            <!-- <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
              Save Changes
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import Icon from './Icon.vue';
export default {
    name: "large-modal",
    data() {
        return {
            showModal: false
        };
    },
    methods: {
        toggleModal: function () {
            this.showModal = !this.showModal;
        }
    },
    components: { Icon }
}
</script>