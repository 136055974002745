<template>
    <nav class="font-sans flex flex-col text-center sm:flex-row sm:text-left sm:justify-between py-4 px-6 bg-white shadow sm:items-baseline w-full">
  <!-- <div class="mb-2 sm:mb-0">
    <a href="/" class="text-2xl no-underline text-grey-darkest hover:text-blue-dark">Equate A Date</a>
  </div> -->
  <div class="flex">
    <span class="px-5">Equate a Date </span>
    <FAQ></FAQ>
    <!-- <a href="/two" class="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2"><Icon icon="question-circle"></Icon></a>
    <a href="/three" class="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2"><Icon icon="share"></Icon></a>
    <a href="/three" class="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2"><Icon icon="gear"></Icon></a> -->
  </div>
</nav>
</template>

<script>
export default {
 methods:{
    toggleModal: function(){
    }
 }
}
</script>


<style>

</style>